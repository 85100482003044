///////////////////////////////////

// Cookie compliance modifications

.sliding-popup-bottom {
  background: rgba(0,0,0,0.7)!important;
  p {
    font-size: 0.9em!important;
  }
}

#sliding-popup .popup-content #popup-buttons button {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  font-weight:normal;
}

#sliding-popup .popup-content {
  max-width: $max-width;
  padding: 0 10px;
}

#popup-buttons {
  margin-top: 10px !important;
}

///////////////////////////////////


// Tagadelic tag cloud
.block-tagadelic-taxonomy {
  a.more {
    display: none;
  }
}

///////////////////////////////////

// Refills
// Fade In - Using class .fade-in-element

.js-fade-element-hide {
  opacity: 0;
}

.js-fade-element-show {
  @include transition (all 0.4s ease-in-out);
  opacity: 1;
}



// Pagers

  ul.pager {
  margin: 20px 0;
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $large-screen: em(860) !default;
  $base-font-color: $dark-gray !default;
  $pagination-border-color: $base-border-color;
  $pagination-border: 1px solid $pagination-border-color;
  $pagination-background: lighten($pagination-border-color, 10);
  $pagination-hover-background: lighten($pagination-background, 5);
  $pagination-color: $base-font-color;

  text-align: center;

    li {
      display: inline;
      list-style: none;
      margin: 0;

    }

    ul li {
      display: none;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        display: inline;
      }

      @include media($large-screen) {
        display: inline;
      }
    }

    li a {
      @include transition (all 0.2s ease-in-out);
      background: $pagination-background;
      border-radius: $base-border-radius;
      border: $pagination-border;
      color: $pagination-color;
      outline: none;
      padding: ($base-spacing / 4) ($gutter / 2);
      text-decoration: none;

      &:hover,
      &:focus {
        background: $pagination-hover-background;
        color: $action-color;
      }
    }
      .pager-current {
        background: $pagination-hover-background;
        border: $pagination-border;
        border-radius: $base-border-radius;
        padding: ($base-spacing / 4) ($gutter / 2);
      }
  }


// Webforms

.webform-component-date {
  select {
    display: inline-block;
  }
  .chosen-container-single .chosen-single {
    height: 25px;
    //margin-top: -10px;
  }
}


.webform-component--from,
.webform-component--to,
.webform-component--total-school-days {
  display: inline-block;
  margin-right: 2em;
}


// User account pages

.field-name-field-first-name.field-widget-text-textfield.form-wrapper ,
.field-name-field-last-name.field-widget-text-textfield.form-wrapper,
.form-text.form-email,
.form-type-textfield.form-item-name,
.form-type-password.form-item-pass .form-text {
  max-width: 290px;
}


// Calendar

.calendar-list-view {
  h3 {
    margin-top: 20px;
  }
  @include zebra-rows;
  .view-content {
    margin-bottom: 40px;
  }
  @include media($phablet) {
    .views-field-field-date-time {
      display: inline-block;
      width: 220px;
    }
    .views-field-title {
      display: inline-block;
    }
  }

}


// Letters

.letter-archive {
  @include zebra-rows;
}






// Misc

.view-display-id-block_3 {
  .views-row-1 {
    margin-top: 10px;
  }
}
