@mixin outer-wrapper {
	max-width: $max-width;
	margin: auto;
	padding: $grid-padding;
	@include media(1215px) {
		padding: 0 0;
	}

}


@mixin boxshadow {
	-webkit-box-shadow: 0 8px 6px -6px $shadow-color;
	-moz-box-shadow: 0 8px 6px -6px $shadow-color;
	box-shadow: 0 8px 6px -6px $shadow-color;
}

@mixin border {
	border: 1px solid $base-border-color;
	border-radius: 2px;
}


@mixin imgzoom {
	li {
		overflow: hidden;
	}
	img {
		transition:all .2s ease-in-out;
		-webkit-transition:all .2s ease-in-out;
		-moz-transition:all .2s ease-in-out;
		-ms-transition:all .2s ease-in-out;
		-o-transition:all .2s ease-in-out;
	}

	img:hover {
		transform:scale(1.05);
		-webkit-transform:scale(1.1);
		-moz-transform:scale(1.1);
		-ms-transform:scale(1.1);
		-o-transform:scale(1.1);
	}
}


@mixin equal-width-horz-list-items {
	@for $i from 1 through 100 {
    li:first-child:nth-last-child(#{$i}),
    li:first-child:nth-last-child(#{$i}) ~ li {
    width: 100% / $i }
  }
}




@mixin button-style-1 {
	@include linear-gradient($midblue, $darkblue);
	&:hover {
    	@include linear-gradient($darkblue, $midblue);
    } 
    display: inline-block;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid black;
    padding: 0.3em 0.5em;
    text-decoration: none;
    color: white;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    p {
    	margin: 0;
    }
    a {
    	color: white;
    }
}
.button-style-1 {
	@include button-style-1;
}


@mixin button-style-2 {
	@include linear-gradient($silver, $palegrey);
	&:hover {
    	@include linear-gradient($palegrey, $silver);
    } 
    display: inline-block;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid $grey;
    padding: 0.3em 0.5em;
    text-decoration: none;
    color: $charcoal;
    cursor: pointer;
    font-size: 1em;
    font-weight: normal;
    text-align: center;

    p {
    	margin: 0;
    }
    a {
    	color: $charcoal;
    }
}

.button-style-2 {
	@include button-style-2;
}

@mixin zebra-rows {
	.views-row {
		border-bottom: 1px solid $base-border-color;
		padding: 10px 5px;
		margin-bottom: 0;
	}
	.views-row-1 {
		border-top: 1px solid $base-border-color;
	}
	.views-row-odd {
		background: whitesmoke;
	}
}
