$charcoal:						         #333333;
$darkgrey:						         #5A5A5A;
$grey:							           #858585;
$midgrey:						           #B6B6B6;
$lightgrey:						         #B6B6B6;
$palegrey:						         #E6E6E6;
$whitesmoke:					         #F5F5F5;
$silver:						           #F9F9F9;

$darkblue:						         #1A237E;
$midblue:						           #3F51B5;
$lightblue:						         #E8EAF6;
$altblue:						           #03A9F4;

$beige:						             #F0EDE5;
$red:                          #D0021B;
$purple:                       #662D91;
$green:                        #2CB673;
$gold:                         #D1A21D;


$body-font-color:				       $charcoal;
$link-color:					         $midblue;
$link-color-hover:			       $altblue;

$shadow-color: 					       $grey;
$base-border-color:			       $grey;


// Top bar and user menu
$top-bar-color:					       $darkgrey;
$user-menu-link-color:			   white;
$user-menu-hover-color:			   white;

// User bar and main menu
$main-menu-wrapper-color:		   $green;
$main-menu-hover-color:			   $gold;
$main-menu-link-color:			   white;
$main-menu-hover-link-color:	 white;
$mobile-menu-bg-color:			   $green;
$hamburger-color:				       white;


// Page body
$body-bg-color:					       white;
$hero-text-color:				       white;

// Background fills
$header-color: 					       $purple;
$featured-bg-color:				     $purple;
$well-bg:						           whitesmoke;

// Blocks
$block-style-1-header-bg:		   $purple;
$block-style-2-header-bg:		   $green;
$block-style-1-header-color:	 white;
$block-style-2-header-color:	 white;
$block-style-1-bg:			       white;
$block-style-2-bg:				     white;
$section-box-bg-color:			   whitesmoke;

// Footer
$footer-bg-color:				       whitesmoke;



.solid-color-1 {
	background: $darkblue;
}
.solid-color-2 {
	background: $midblue;
}
.solid-color-3 {
	background: $lightblue;
}
.gradient-fill-1 {
	@include linear-gradient($darkblue, $midblue);
}

