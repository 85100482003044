// Shopping cart block
.view-commerce-cart-block {
	table tbody td,
	.view-footer {
		padding: 5px;
	}
	table {
		margin: 0;
		tr, td {
			padding: 0 !important;
		}
	}
	.line-item-summary {
		margin-top: 10px;
		margin-bottom: 0;
	}
	.views-field-quantity {
		width: 3em;
		text-align: left;
	}
	.views-field-commerce-total {
		width: 5em;
	}
	ul.links.inline {
		padding: 0;
		margin-top: 1.5em;
		li {
			display: inline-block;
			margin-left: 1em;
		}
	}
	tr.even,
	tr.odd {
		background: white;
		border-bottom: 1px solid $base-border-color;
	}
	.view-footer p {
		margin: 0;
		padding: 0;
	}

	.line-item-summary-checkout a {
		@include button-style-1;
		margin-right: 0;
		font-size: 1em;
		padding: 5px 10px !important;
	}

	.delete-line-item.form-submit {
		@include button-style-2;
		font-size: 0.8em;
		margin-top: 0.2em;
		&:hover {
			color: red;
		}
	}
}
.cart-empty-block {
	margin: 5px 10px;
}


// Cart
.page-cart {
	.form-submit {
		margin-left: 10px;
	}
	input.delete-line-item  {
		padding: 5px;
		@include button-style-2;
		margin-top: 0;
	}

	.line-item-total {
		margin-top: 15px;
    display: inline-block;
    @include border;
    padding: 0.6em;
	}
}



// Checkout
.page-checkout {
	.fieldset {
		background-color: white;
	}
	.checkout-buttons, {
	.checkout-continue {
			margin-right: 10px;
			border-radius: 5px;
		}
	}
}

.page-checkout {
	.checkout-advice-block {
		@include media($laptop) {
			margin-top: 20px;
		}
		@include media($desktop) {
			margin-top: 26px;
		}
	}
}

.page-checkout-review {
	.checkout-advice-block {
		@include media($laptop) {
			margin-top: 63px;
		}
		@include media($desktop) {
			margin-top: 70px;
		}
	}
}


.page-checkout,
.checkout-review {
	tr.even, tr.odd {
		background-color: white;
		border-bottom: $base-border-color;
	}
	table {
		border: 1px solid $base-border-color;
	}

	.addressfield-container-inline > div.form-item  {
		margin: 0;
		width: 100%;
	}
}



.field-name-commerce-customer-address {
	max-width: 550px;
}


// Order History
.commerce-order-history {
	th.views-field-commerce-total,
	th.views-field-quantity,
	th.views-field-commerce-unit-price {
		width: 5em;
	}
}


// Checkout
#commerce-checkout-form-checkout {
		#edit-cart-contents,
		#edit-customer-profile-billing {
		background: $whitesmoke;
		padding: 10px;
	}
	tr {
		background-color: white;
	}

	.commerce-price-formatted-components {
		@include media($tablet) {
			max-width: 300px;
		}
	}
}

.view-commerce-cart-summary  {
	.view-content th:nth-child(1) {
		width: 50%;
		@include media($tablet) {
			width: 70%;
		}
	}
}
.view-commerce-cart-summary  {
	.view-content th:nth-child(3) {
		width: 10%;
	}
}

.views-field-quantity {
	text-align: center;
}

#commerce-checkout-form-review {
	tr.pane-title.odd.odd {
		background: $palegrey;
		border: 1px solid $base-border-color;
	}
	tr.pane-data.even.even {
		background: $whitesmoke;
		border: 1px solid $base-border-color;
	}
	.commerce-price-formatted-components {
		@include media($tablet) {
			max-width: 300px;
		}
		border: 1px solid $base-border-color;
	}

}
#edit-buttons {
	padding: 15px 10px;
}
#edit-commerce-payment {
	padding: 10px;
	background: $whitesmoke;
	border: 1px solid $base-border-color;
}

#edit-back,
#edit-cancel {
	@include button-style-2;
	margin-left: 10px;
}

// Cart and checkout
.checkout-help {
	margin-bottom: 2em;
}


// Orders
@include media($mobile) {
	.view-commerce-user-orders {
		.views-field-changed,
		.views-field-created {
			display: none;
		}
	}
}

@include media($phablet) {
	.view-commerce-user-orders {
		thead {
			background: $whitesmoke;
		}
		.views-field-changed,
		.views-field-created {
			display: table;
		}
	}
}










