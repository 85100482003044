// BASED ON THE NEAT GRID SYSTEM
// Examples: http://http://neat.bourbon.io/examples/
// Documentation: http://thoughtbot.github.io/neat-docs/latest/
// Github: https://github.com/thoughtbot/neat

@import "../../neato/bower_components/neat/app/assets/stylesheets/neat-helpers";
@import "../../neato/bower_components/neat/app/assets/stylesheets/neat";


//Grid Settings
$visual-grid: 			false;
$visual-grid-color: 	pink;
$visual-grid-index: 	front;
$visual-grid-opacity: 	0.2;
$grid-columns: 			12;


$max-width: 			1200px;
//$max-width: 			100%;
$gutter: 				em(30px);
$grid-padding: 			0 14px;


//Breakpoints
$mm-mobile: 	new-breakpoint(min-width 00.00em max-width 29.99em);	// Up to 480px
$mm-phablet: 	new-breakpoint(min-width 30.00em max-width 37.49em); 	// Up to 600px
$mm-tablet: 	new-breakpoint(min-width 37.50em max-width 47.99em); 	// Up to 768px
$mm-laptop: 	new-breakpoint(min-width 48.00em max-width 63.99em);	// Up to 1024px
$mm-desktop: 	new-breakpoint(min-width 64.00em max-width 89.99em);	// Up to 1440px
$mm-highdef: 	new-breakpoint(min-width 90.00em max-width 119.99em);	// Up to 1920px

$mobile: 			new-breakpoint(min-width 00.00em);	// Up to 480px
$phablet: 		new-breakpoint(min-width 30.00em); 	// Up to 600px
$tablet: 			new-breakpoint(min-width 37.50em); 	// Up to 768px
$laptop: 			new-breakpoint(min-width 48.00em);	// Up to 1024px
$desktop: 		new-breakpoint(min-width 64.00em);	// Up to 1440px
$highdef: 		new-breakpoint(min-width 90.00em);	// Up to 1920px
$cinema: 			new-breakpoint(min-width 120.0em);	// Over 1920px


// ------------------------------------------------------------
// Omega Reset by Josh Fry
// ------------------------------------------------------------

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}


////////////////////////////////////////////////////////////////

// Provides a class must must surround any span columns
.outer-wrapper {
  @include outer-container;
}

// Grid placement
.span-columns-1 {
	@include span-columns(1);
}
.span-columns-2 {
	@include span-columns(2);
}
.span-columns-3 {
	@include span-columns(3);
}
.span-columns-4 {
	@include span-columns(4);
}
.span-columns-5 {
	@include span-columns(5);
}
.span-columns-6 {
	@include span-columns(6);
}
.span-columns-7 {
	@include span-columns(7);
}
.span-columns-8 {
	@include span-columns(8);
}
.span-columns-9 {
	@include span-columns(9);
}
.span-columns-10 {
	@include span-columns(10);
}
.span-columns-11 {
	@include span-columns(11);
}
.span-columns-12 {
	@include span-columns(12);
}

/////////////////////////////////////////////

.shift-1 {
	@include shift(1);
}
.shift-2 {
	@include shift(2);
}
.shift-3 {
	@include shift(3);
}
.shift-4 {
	@include shift(4);
}
.shift-5 {
	@include shift(5);
}
.shift-6 {
	@include shift(6);
}
.shift-7 {
	@include shift(7);
}
.shift-8 {
	@include shift(8);
}
.shift-9 {
	@include shift(9);
}
.shift-10 {
	@include shift(10);
}
.shift-11 {
	@include shift(11);
}

