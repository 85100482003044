body {
	background: $body-bg-color;
	margin: 0;
}


#messages,
.main-wrapper {
	padding: $grid-padding;
}



.topbar-wrapper {
	overflow: visible;
	background: $top-bar-color;
	padding: $grid-padding;
}

.header-wrapper {
	padding: $grid-padding;
	background: $header-color;
	@include media($mobile) {
		display: none;
	}
	@include media($laptop) {
		display: block;
	}
}


.navigation-wrapper {
	border-top: 1px solid $grey;
	border-bottom: 1px solid $grey;
	background: $main-menu-wrapper-color;
	padding: $grid-padding;
	@include media($mobile) {
		display: none;
	}
	@include media($laptop) {
		display: block;
	}
	@include boxshadow;
}

///////////////////////////////////////////////

// Featured & Preface Area

#featured,
#preface {
	@include clearfix;
	.span-columns-6 {
		@include span-columns(12);
		@include media($laptop) {
			@include span-columns(6);
			@include omega(2n);
		}
	}
	.span-columns-4 {
		@include span-columns(12);
		@include media(900px) {
			@include span-columns(4);
			@include omega(3n);
		}
	}
	.span-columns-3 {
		@include span-columns(12);
		@include media($laptop) {
			@include span-columns(6);
			@include omega(2n);
		}
		@include media($desktop) {
			@include omega-reset(2n);
			@include span-columns(3);
			@include omega(4n);
		}
	}
}

.featured-wrapper {
	padding: $grid-padding;
	background: $featured-bg-color;
}

#featured .block-style-2 {
	margin-bottom: 0px;
}


///////////////////////////////////////////////

// Content Area

#content {
	padding: 10px 0 0;
	@include media($tablet) {
		padding: 10px 0 1em;
	}
	@include media($laptop) {
		min-height: 400px;
	}
}

.front #content {
	padding: 0;
}
.front main  {
	@include media($mobile) {
		padding-top: 14px;		
	}
	@include media($laptop) {
		margin-top: 10px;
		padding-top: 0;		
	}
}

.not-front #sidebar-second {
	@include media($laptop) {
		padding-top: 42px;
	}
}

.not-front #sidebar-second {
	@include media($desktop) {
		padding-top: 51px;
	}
}


///////////////////////////////////////////////

// Footer

.footer-wrapper {
	//overflow: auto;
	border-top: 1px solid $base-border-color;
	padding: $grid-padding;
	background: $footer-bg-color;

	.footer-one {
		padding: 1em 0;
	}
	.footer-two {
		padding: 1em 0;
		@include media($tablet) {
			.block {
				display: block;
				text-align: right;
			}
		}
	}
}




// Sticky Footer //

/*
@include media($tablet) {
	html, body {
    height: 100%;
	}

	.page {
	    min-height: 100%;
	    position: relative;
	}
	.main-wrapper {
	    padding-bottom: 400px; //* same as .footer-wrapper height 
	}
	.footer-wrapper {
	    position: absolute;
	    bottom: 0;
	    overflow: auto;
	    width: 100%;
	    height: 400px; // same as #content padding-bottom 
	}
}
*/





