.tabs {
  &.primary {
    ///////////////////////////////////////////////////////////////////////////////////
    $base-border-color: gainsboro !default;
    $base-background-color: white !default;
    $base-spacing: 1.5em !default;
    $base-accent-color: #477DCA !default;
    $base-link-color: $base-accent-color !default;
    $dark-gray: #333 !default;
    $medium-screen: em(640) !default;
    //////////////////////////////////////////////////////////////////////////////////

    $tab-link-color: $dark-gray;
    $tab-border: 1px solid $base-border-color;
    $tab-active-background: $base-background-color;
    $tab-inactive-color: $base-background-color;
    $tab-inactive-hover-color: silver;
    $tab-mode: $medium-screen;


    @include clearfix;
    padding: 0;
    margin: 5px 0;
    border-bottom: 1px solid $base-border-color;

    li {
      list-style: none;
      //min-width: 100px;
      display: inline-block;

      @include media($tab-mode) {
        display: inline;
      }

      a {
        background-color: $tab-inactive-color;
        border-top: $tab-border;
        border-left: $tab-border;
        border-right: $tab-border;
        @include border-top-radius(5px);
        margin-right: 0;
        color: $tab-link-color;
        display: block;
        font-weight: 400;
        //padding: ($base-spacing / 6) $gutter;
        padding: 3px 8px;
        text-decoration: none;

        @include media($tab-mode) {
          display: inline-block;
          //border-top: 0;
        }

        @include media($mm-mobile) {
          font-size: 0.8em;
        }


        &:hover {
          color: $base-link-color;
        }

        &:focus {
          outline: none;
        }

        &.active {
          border-bottom: 1px solid white;
          margin-bottom: -1px;

          @include media($tab-mode) {
            border: $tab-border;
            border-bottom-color: $tab-active-background;
            margin-bottom: -1px;
          }
        }
      }

      .tab-content {
        display: none;
        padding: $base-spacing $gutter;
        width: 100%;

        @include media($tab-mode) {
          border-top: $tab-border;
          float: left;
        }
      }
    }
  }
}


@include media($mobile) {
  .password-strength {
    float: none;
    padding-bottom: 10px;
    overflow: hidden
  }
}
@include media($tablet) {
  .password-strength {
    float: right;
    padding-bottom: 0;
  }
}


