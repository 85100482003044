@import "../base/mixins";


body {
    animation: fadein 0.5s;
    -moz-animation: fadein 0.5s; /* Firefox */
    -webkit-animation: fadein 0.5s; /* Safari and Chrome */
    -o-animation: fadein 0.5s; /* Opera */
}


.block-style-1 {
   margin: 15px 0 30px;
   border: 1px solid white;
   background: $block-style-1-bg;
	h2 {
		margin: 0 0 10px 0;
		padding: 10px;
		background: $block-style-1-header-bg;
		color: $block-style-1-header-color;
		border: 1px solid $base-border-color;
		border-width: 1px 1px 2px 1px;
	}
	h4 {

	}
}

.block-style-2 {
   margin: 15px 0 30px;
   border: 1px solid $base-border-color;
   border-width: 1px 1px 2px 1px;
   background: $block-style-2-bg;
   h1 {
   	display: none;
   }
	h2 {
		margin: 0 0 10px 0;
		padding: 10px;
		color: $block-style-2-header-color;
		background: $block-style-2-header-bg;
		border-bottom: 1px solid $base-border-color;
		i {
			line-height: 0;
		}
	}

	p,ul,ol,
	.view {
		padding: 0 10px 10px;;
	}
	.view-footer p {
		padding: 0;
	}
	.views-field-mail p {
		padding: 0;
	}
	li {
		margin-bottom: 0.5em;
	}
}

#preface .block-style-2 {
	margin: 30px 0 15px;
}


.button-style-1 {
	@include button-style-1;
}


.well {
	background: $well-bg;
	padding: 10px;
	margin-bottom: 30px;
	@include border;
}


.site-logo {
  margin-bottom: -8px;
}

// forum

.forum-table-topics {
  .views-field.views-field-topic-icon {
    width: 40px;
  }
  .views-field-comment-count {
    width: 100px;
  }
  .views-field-last-updated {
    width: 250px;
  }
  .forum-topic-title {
    font-size: 1.3em;
    font-weight: 700;
  }
}

.forum-table-topics,
.forum-table {
  td {
    padding: 0 0.5em;
  }
}

.forum-list-icon-wrapper {
  margin-left: 2px;
}

.forum-table-topics td:first-child,
.forum-table td:first-child  {
  padding: 0 0.2em;
}

.forum-post-links {
  a {
    margin: 0 5px;
  }
}


