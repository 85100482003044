////////////////////////////////////////////////

// Anti-FOUT technique for easing in Typekit web fonts
// http://richardsweeney.com/a-suggested-fix-for-the-dreaded-fout/

/**/

h1, h2, h3, h4, h5, h6, p, ul, ol, dl, span, a {
  opacity: 0;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
  transition: opacity 1s ease-in;
}


.html {
  h1, h2, h3, h4, h5, h6, p, ul, ol, dl, span, a {
    opacity: 1;
  }
}


////////////////////////////////////////////////

// @FONT-FACE

@import url(https://fonts.googleapis.com/css?family=Oswald);//@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700);

$header-font: 'Oswald', sans-serif;
$menu-font: 'Oswald', sans-serif;
$body-font: sans-serif;

////////////////////////////////////////////////

//Default link colors

a:link, a:visited {
  color: $link-color;
}
a:hover, a:active, a:focus {
  color: $link-color-hover;
}

////////////////////////////////////////////////

//Base typography

body {
  font-size: 16px;
  font-family: $body-font;
  color: $body-font-color;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

p {
  margin: 0 0 1em 0
}


// Fix for crappy type in admininstration menu
#admin-menu,
.user-menu {
  -webkit-font-smoothing: subpixel-antialiased !important;
}

////////////////////////////////////////////////

//Headings

//Based on 1.250 Major Third pattern

h1,h2,h3,h4 {
  margin-bottom: 10px;
  margin-top: 0px;
  font-family: $header-font;
  font-weight: 400;
}


h1 {
font-size: 1.562em;
text-transform: uppercase;
font-weight: 700;
}
h2 {
  font-size: 1.25em;
}
h3 {
  font-size: 1em;
}
h4 {
  font-size: 1em;
  font-weight: 700;
}


@include media($desktop) {
  h1 {
    font-size: 2.441em;
  }
  h2 {
    font-size: 1.953em;
  }
  h3 {
    font-size: 1.563em;
  }
  h4 {
    font-size: 1.25em;
  }
}


.front-page-banner {
    p {
      font-family: $header-font;
    }
    h2 {
        font-family: $header-font !important;
        //text-transform: uppercase;
    }
}



////////////////////////////////////////////////

// Main Menu

.nice-main-menu,
.main-menu {
  font-family: $menu-font;
  font-weight: 400;
}

////////////////////////////////////////////////

// Blockquote

blockquote {
  margin-left: 5px;
  padding: 10px 0 0 3em;
  font-style: italic;
  font-size: 1.25em;
  background-image: url('../images/quotemarks.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  strong {
    font-weight: bold;
  }
  p {
    //padding-left: 20px;
  }
}

////////////////////////////////////////////////

// Lists

ul {
  list-style: disc inside none;
}
ol {
  list-style: decimal inside none!important;
}

.node ul,
.node ol {
  margin-bottom: 1em;
  li {
    margin-bottom: 0.3em;
  }
}

.view-footer {
  li {
    margin-bottom: 0.3em;
  }
}

.item-list ul {
  padding: 0;
  margin: 0;
  li {
    padding: 0;
    margin: 0;
  }
}


