
@import "../base/grid";



.field-name-field-related-content-label {
  font-size: 1.367em;
  font-weight: bold;
}


@include media($tablet) {
  .field-name-field-related-content-label {
    font-size: 1.953em;
  }
}



.node-snippet {
	h3 {
		margin-bottom: 5px;
	}
	position: relative;
	height: 100%;
	padding-bottom: 20px;
	.field-name-field-date-time {
		display: block;
		padding: 5px 10px;
	}
}



.node-teaser {
	overflow: auto;
	margin-bottom: 20px;
	.node-readmore {
		display: none;
	}
	.field-name-field-date-time {
		display: block;
		padding: 0 0 5px;
	}
	.teaser-thumbnail {		float: left;
		overflow: hidden;
		list-style-type: none;
		margin-top: 5px;
		@include media($mobile) {
			margin-right: 10px;
		}
		@include media($phablet) {
			margin-right: 20px;
		}
		img:first-child {
			@include border;
			@include boxshadow;
			@include media($mobile) {
				width: 140px;
				height: auto;
			}
			@include media($phablet) {
				width: 190px;
				height: auto;
			}
		}
	}

	ul {
		margin-right: 20px;
		margin-bottom: 0;
		padding: 0;
	}
}


.card {
	border: 1px solid lightgrey;
	padding-bottom: 10px;
	margin-bottom: 30px;
	@include media($tablet) {
		margin-bottom: 2.5%;
	}
	overflow: hidden;
	@include imgzoom;
	@include boxshadow;
	img {
		height: auto;
	}

	.field-name-title-field {
		a {
			display: block;
			padding: 0 0 0 10px;
		}
	}
	.field-name-body {
		a {
			display: block;
			color: $body-font-color;
			padding: 0 10px;
		}
	}
	.field-name-field-date-time {
		display: block;
		padding: 5px 10px;
	}
}


.section-box {
	.view-mode-snippet {
		.field-name-body {
			margin: 0 10px;
		}
	}
}







// Fixes erroneous media type label being displayed in teasers
.field-name-field-select-media-type {
	display: none;
}

