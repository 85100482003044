


.hero-wrapper,
#content {
	.bean-hero-block {
		//background-attachment: fixed;
		background-size: cover !important;
		position: relative;
		//@include boxshadow;
		@include media($mobile) {
			height: 380px;
		}
		@include media($laptop) {
			height: 450px;
		}
		.content {
			@include outer-wrapper;
			color: $hero-text-color;
			h2 {
				margin-top: 1em;
				text-shadow: 0px 0px 2px rgba(150, 150, 150, 1);
				font-family: $body-font;
				//font-size: 1.953em;
				font-size: 1.953em;
				//background: rgba(0,0,0,0.3);
				//padding: 10px;
				display: inline-block;
				@include media($tablet) {
					font-size: 4em;
				}
			}
			p {
				font-size: 1.708em;
				text-shadow: 0px 0px 2px rgba(150, 150, 150, 1);
				@include media($tablet) {
					//width: 40%;
					font-size: 2.5em;
				}
			}
			ul {
				margin-top: 5em;
			}
			li {
				@include button-style-1;
				font-size: 1.3em;
				//padding: 1em;

				display: inline-block;
				margin: 10px 10px;
				a {
					color: white;
				}
			}
		}
	}
}


.featured-wrapper,
#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		margin-bottom: 2em;
		@include media($mobile) {
			height: 340px;
			padding: 1em;
		}
		@include media($laptop) {
			height: 300px;
			padding: 2em;
		}
		.content {
			color: $hero-text-color;
			h2 {
				font-family: $body-font;
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			ul {
				//position: absolute;
				//left:0;
				//right:0;
				bottom: 2em;
			}
			li {
				@include button-style-1;
				font-size: 1.3em;
				//padding: 1em;

				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
	}
}


.preface-wrapper,
#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		margin-bottom: 2em;
		@include media($mobile) {
			height: 340px;
			padding: 1em;
		}
		@include media($laptop) {
			height: 300px;
			padding: 2em;
		}
		.content {
			color: $hero-text-color;
			h2 {
				font-family: $body-font;
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			ul {
				//position: absolute;
				//left:0;
				//right:0;
				bottom: 2em;
			}
			li {
				@include button-style-1;
				font-size: 1.3em;
				//padding: 1em;

				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
	}
}



#content  {
	.bean-hero-block {
		.content {
			color: $hero-text-color;
			h2 {
				font-family: $body-font;
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
			}
			ul {
				margin-top: 0.5em;
			}
			li {
				@include button-style-1;
				font-size: 1.3em;
				//padding: 1em;

				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
		@include media($mobile) {
			height: 340px;
			padding: 0em 1em;
			font-size: 90%;
		}
		@include media($laptop) {
			height: 300px;
			padding: 0em 1em;
			font-size: 120%;
		}
	}
}



