
.right {
  float: right;
}
.left {
  float: left;
}

.clear {
	clear: both;
}

.display-inline-block {
	display: inline-block;
}

.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-centre {
	text-align: center;
}
.horz-list {
  li {
    display: inline-block;
  }
}

.views-row {
	margin-bottom: 20px;
}

// Vertical space
.vert-space-10 {
	margin: 10px 0;
}

.vert-space-20 {
	margin: 20px 0;
}

.vert-space-30 {
	margin: 30px 0;
}

.npm {
	p {
		margin: 0;
	}
}