@import "../base/mixins";

.border {
  @include border;
  padding: 10px;
  margin-bottom: 30px;
}

.boxshadow {
	@include boxshadow;
}



// Buttons 

.form-submit {
	@include button-style-1;
}